<script lang="ts" setup>
import Section from '@/js/classes/Section.js';
import useFigureStore from '@/js/stores/figure';
import * as cl_bl from '@component-library/business-logic';
import {
  checkIsPhaseVisible,
  findFieldByIdFromApp,
  getLinkConfigs,
} from '@component-library/business-logic/app';
import { stringifyResult } from '@component-library/business-logic/expression';
import { AVAILABLE_PERMISSIONS } from '@component-library/company-role-profile';
import AlertBox from '@component-library/components/AlertBox.vue';
import useLegacyRootBus from '@component-library/composables/useLegacyRootBus';
import useLegacyStore from '@component-library/composables/useLegacyStore';
import useViewRestriction from '@component-library/composables/useViewRestriction';
import { FieldTypeIds } from '@component-library/fields';
import FormSection from '@component-library/form/FormSection.vue';
import SafetySection from '@component-library/form/SafetySection.vue';
import SubFolderDropdown from '@component-library/form/SubFolderDropdown.vue';
import { App, InputValue, Item } from '@component-library/gather';
import { useGatherSchemaStore } from '@component-library/store/gather-schema';
import { useLinkedFieldsStore } from '@component-library/store/linked-fields';
import { useToastStore } from '@component-library/store/toasts';
import { LayerType } from '@maps/lib/olbm/types';
import _isEqualWith from 'lodash/isEqualWith';
import {
  computed,
  inject,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';
import FormNavigation from './FormNavigation.vue';
import AppLinkConfigList from './linking/AppLinkConfigList.vue';
import Button from '@component-library/components/Button.vue';

const { isViewOnly } = useViewRestriction(AVAILABLE_PERMISSIONS.GATHER);

const gatherSchema = useGatherSchemaStore();
const legacyRootBus = useLegacyRootBus();
const emit = defineEmits([
  'clickCamera',
  'clickVideo',
  'clickSetPreview',
  'clickStartDrawing',
  'isLoading',
  'input',
  'setSampleTitlePlaceholder',
  'subFolderChanged',
  'setIsReadOnly',
  'ready',
  'setTemplateTabId',
  'closeSearch',
  'save',
]);

const legacyStore = useLegacyStore();
const toastStore = useToastStore();
const linkedFieldsStore = useLinkedFieldsStore();
const figureStore = useFigureStore();

const props = defineProps<{
  sample: Item;
  templateTabs: App[];
  inputValues: InputValue[];
  samples: Item[];
  isNonSpatialView: boolean;
  isNonSpatial: boolean;
  toggleSearch: boolean;
  isOnline: boolean;
  isOfflineSample: boolean;
  isReadOnly: boolean;
  sampleIdentifier?: string | null;
  hasChangedInputValues: boolean;
}>();

const formContext = inject<any>('formContext');

/**
 * id
 */
const dataTabSelected = ref(0);
const drawingType = ref<string | null>(null);
const search = ref<string | undefined>();
const selectedSections = ref<any[]>([]);
const formNavigationSize = ref<{ width: number; height: number }>({
  width: 0,
  height: 0,
});
const cssFieldScrollMarginTop = computed<string>(() => {
  return isNavigationVisible.value
    ? `calc(${formNavigationSize.value.height}px + 1rem)`
    : '0px';
});

watch(dataTabSelected, (newValue) => {
  emit('setSampleTitlePlaceholder');
  emit('setTemplateTabId', newValue);
  legacyStore.dispatch('updatePersistence', {
    sampleTabId: newValue,
  });
  setSelectedSections(newValue);
});

const allFields = computed(() => {
  let allFields: any[] = [];
  for (let section of selectedSections.value) {
    allFields = [...allFields, ...section.template_fields];
  }
  return allFields;
});

const availableTemplateTabs = computed(() => {
  return props.templateTabs.filter(
    (t) =>
      (t.drawing_type == drawingType.value ||
        t.drawing_type == 'any' ||
        t.id == dataTabSelected.value) &&
      !t.is_read_only &&
      !cl_bl.template_tab.checkIsPoiApp(props.templateTabs, t.id) &&
      !cl_bl.template_tab.checkIfAppReachedLimit(t) &&
      checkIsPhaseVisible(t, gatherSchema.phases)
  );
});

const enabledApps = computed(() => {
  return availableTemplateTabs.value.filter((app) =>
    figureStore.checkIsAppEnabled(
      app.id,
      props.templateTabs,
      props.isNonSpatialView
    )
  );
});

const templateTab = computed(() => {
  return props.templateTabs.find((t) => t.id === dataTabSelected.value);
});

const subFolders = computed(() => {
  return templateTab.value?.sub_folders ?? [];
});

const needsToChooseAnApp = computed(() => {
  return (
    availableTemplateTabs.value.length > 1 &&
    !props.sample.template_to_select &&
    !props.sample.template_tab_id
  );
});

const isAppLinkConfigListVisible = computed<boolean>(() => {
  if (!templateTab.value) {
    return false;
  }

  const { drawing_type } = templateTab.value;
  return (
    ['any', 'point'].includes(drawing_type) &&
    getLinkConfigs(templateTab.value).length > 0
  );
});

const isSubFolderDropdownVisible = computed(() => {
  if (!subFolders.value.length) {
    return false;
  }

  // The is_sub_folder_applicable is not boolean for new samples.
  if (typeof props.sample.is_sub_folder_applicable !== 'boolean') {
    return (
      ['point', 'any'].includes(templateTab.value?.drawing_type ?? '') &&
      !['polygon', 'polyline'].includes(drawingType.value ?? '')
    );
  }

  return props.sample.is_sub_folder_applicable;
});
const isNavigationVisible = computed(() => {
  return selectedSections.value.length > 1;
});

function setSelectedSections(selectedDataTab) {
  const dataTab = {
    ...props.templateTabs.find((t) => t.id == selectedDataTab),
  };

  if (dataTab) {
    selectedSections.value = dataTab.sections ?? [];
    return;
  }

  selectedSections.value = [];
}

function clickCamera(data) {
  emit('clickCamera', data);
}
function clickVideo(data) {
  emit('clickVideo', data);
}
function clickSetPreview(data) {
  emit('clickSetPreview', data);
}
function clickStartDrawing(data) {
  emit('clickStartDrawing', data);
}
function isLoading(value) {
  emit('isLoading', value);
}

function updateInputValue({
  inputValue,
  field,
  sectionIndex,
  templateTabId,
  isDefaultInputValue,
}) {
  if (Number.isInteger(field)) {
    field = findFieldByIdFromApp(templateTab.value!, field)!;
  }
  inputValue.template_tab_id = templateTabId;
  const index = props.inputValues.findIndex(
    (iv) =>
      iv.template_field_id == field.id &&
      iv.template_section_index == sectionIndex
  );

  if (index === -1) {
    props.inputValues.push(inputValue);
    formContext.changedInputValueQueue.push(inputValue);
    legacyRootBus.$emit('inputValueUpdated', {
      inputValue,
      isDefaultInputValue,
      isChanged: true,
    });
    return;
  }

  const oldInputValue = props.inputValues[index];
  props.inputValues[index] = inputValue;
  // Hack to cause reactivity.
  props.inputValues.push({} as any);
  props.inputValues.pop();
  formContext.changedInputValueQueue.push(inputValue);

  const isChanged = !_isEqualWith(
    {
      value: oldInputValue.value,
      value2: oldInputValue.value2,
      options: oldInputValue.options,
    },
    {
      value: inputValue.value,
      value2: inputValue.value2,
      options: inputValue.options,
    },
    (objValue, othValue, key) => {
      if (field.field_type_id === FieldTypeIds.ADDRESS && key === 'value2') {
        // For handling the special chars like the slash
        const normalize = (value) => {
          return typeof value === 'string'
            ? JSON.stringify(JSON.parse(value))
            : typeof value === 'object'
            ? (value = JSON.stringify(value))
            : value;
        };
        return normalize(objValue) === normalize(othValue);
      } else if (
        field.field_type_id === FieldTypeIds.EXPRESSION &&
        key === 'value'
      ) {
        return (
          stringifyResult(field, objValue) === stringifyResult(field, othValue)
        );
      }
    }
  );
  legacyRootBus.$emit('inputValueUpdated', {
    inputValue,
    isDefaultInputValue,
    isChanged,
  });
}

function setDefaultValues() {
  for (let aInputValue of props.inputValues) {
    if (!aInputValue.id && aInputValue.value == null) {
      const section =
        selectedSections.value[aInputValue?.template_section_index];
      if (!section) {
        return;
      }
      const aField = section.template_fields.find(
        (f) => f.id == aInputValue.template_field_id
      );
      if (!aField) {
        return;
      }
      const value = aField?.options?.default || null;
      updateInputValue({
        inputValue: { ...aInputValue, value },
        field: aInputValue.template_field_id,
        sectionIndex: aInputValue.template_section_index,
        templateTabId: aInputValue.template_tab_id,
        isDefaultInputValue: true,
      });
    }
  }
}

async function handleFetchData({
  section,
  index,
  inputValues,
  updateInputValue,
  setIsFetching,
}) {
  try {
    await Section.wrap(section).onFetchData(legacyStore, {
      index,
      inputValues,
      updateInputValue,
      setIsFetching,
    });
  } catch (error) {
    toastStore.unexpected();
  }
}

function handleFormNavigationSizeChange(size) {
  formNavigationSize.value = size;
}

onMounted(() => {
  legacyRootBus.$on('updateInputValue', updateInputValue);
  let selectedTab =
    props.sample.template_to_select || props.sample.template_tab_id;

  linkedFieldsStore.clearCache();

  if (!props.templateTabs.find((t) => t.id == selectedTab)) {
    if (props.templateTabs.length === 0) {
      return;
    }

    drawingType.value = 'point';

    if (props.isNonSpatial) {
      drawingType.value = 'non-spatial';
    } else if (props.sample.layer) {
      const layerType = props.sample.layer.options.type;

      drawingType.value = layerType;
    } else if (props.sample.geojson) {
      let { type } = props.sample.geojson.properties;
      if ([LayerType.SITE_BOUNDARY].includes(type)) {
        type = LayerType.POLYGON;
      }
      drawingType.value = type;
    }

    selectedTab =
      enabledApps.value.find((t) => {
        return t.id === legacyStore.state.persistence.sampleTabId;
      })?.id ??
      enabledApps.value.find((t) => t.drawing_type == drawingType.value)?.id ??
      enabledApps.value.find((t) => t.drawing_type == 'any')?.id ??
      enabledApps.value[0]?.id;
  }

  dataTabSelected.value = selectedTab;

  emit(
    'setIsReadOnly',
    templateTab.value?.is_read_only ||
      props.isOfflineSample === true ||
      isViewOnly()
  );

  nextTick(setDefaultValues);
  nextTick(() => emit('ready'));
});

onBeforeUnmount(() => {
  legacyRootBus.$off('updateInputValue', updateInputValue);
});

defineExpose({
  getTemplateTab() {
    return templateTab.value;
  },
  needsToChooseAnApp,
});
</script>

<template>
  <div
    v-if="templateTab"
    ref="form"
    id="sample-form"
    style="min-height: 30rem"
    :style="isReadOnly ? 'cursor: not-allowed;pointer-events: none;' : ''"
  >
    <template v-if="needsToChooseAnApp">
      <div class="form-group mb-2">
        <label class="form-label"> Select an App to use </label>

        <div class="d-flex gap-3">
          <select class="form-control" v-model="dataTabSelected">
            <option
              v-for="dataTab of availableTemplateTabs"
              :key="dataTab.id"
              :value="dataTab.id"
              :disabled="!enabledApps.some((ea) => ea.id === dataTab.id)"
            >
              {{ dataTab.title }}
            </option>
          </select>

          <Button @click="emit('save')" color="secondary">
            <i class="fas fa-check"></i>
            Apply
          </Button>
        </div>
      </div>
    </template>

    <AppLinkConfigList
      v-if="isAppLinkConfigListVisible"
      class="mb-3"
      icon-button-container="#sample-form"
      :appId="dataTabSelected"
      :apps="templateTabs"
      :item="sample"
      :has-changed-input-values="hasChangedInputValues"
      :phases="gatherSchema.phases"
    />

    <!-- The dropdown to select a sub-folder -->
    <template v-if="isSubFolderDropdownVisible">
      <SubFolderDropdown
        class="mt-2"
        style="z-index: 10000"
        :id="`subFolderDropdown_${dataTabSelected}`"
        :subFolders="subFolders"
        :value="sample.sub_folder"
        @subFolderChanged="$emit('subFolderChanged', $event)"
      />
      <hr />
    </template>

    <FormNavigation
      v-if="isNavigationVisible"
      :sections="selectedSections"
      :inputValues="inputValues"
      @size-change="handleFormNavigationSizeChange"
    />

    <div v-if="toggleSearch" class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="Search fields..."
        autocomplete="off"
        v-model.trim="search"
        @keydown.enter.prevent
      />
      <button
        class="btn btn-danger"
        type="button"
        @click.prevent="
          search = undefined;
          emit('closeSearch');
        "
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <p v-if="selectedSections.length === 0">No fields for this App.</p>

    <template v-for="section of selectedSections">
      <FormSection
        v-if="!section.is_health_safety"
        :key="`section-${section.id}`"
        :sample="sample"
        :search="search"
        :section="section"
        :allFields="allFields"
        :allSections="selectedSections"
        :samples="samples"
        :inputValues="inputValues"
        :showAddSection="true"
        :isNavigationVisible="isNavigationVisible"
        :templateTab="templateTab"
        :sampleIdentifier="sampleIdentifier"
        :css-field-scroll-margin-top="cssFieldScrollMarginTop"
        @isLoading="isLoading"
        @input="$emit('input')"
        @clickCamera="clickCamera"
        @clickVideo="clickVideo"
        @clickSetPreview="clickSetPreview"
        @clickStartDrawing="clickStartDrawing"
        @fetchData="handleFetchData"
        @clearSearch="search = undefined"
      />
      <SafetySection
        v-else
        :key="`section-safety-${section.id}`"
        :templateTab="templateTab"
        :sample="sample"
        :search="search"
        :section="section"
        :allFields="allFields"
        :samples="samples"
        :inputValues="
          inputValues.filter((iV) => iV.template_section_id == section.id)
        "
        :css-field-scroll-margin-top="cssFieldScrollMarginTop"
        @isLoading="isLoading"
        @input="$emit('input')"
        @clearSearch="search = undefined"
      />
    </template>
  </div>
  <AlertBox v-else type="info">
    No app with the <b>{{ drawingType }}</b> collection type was found.
  </AlertBox>
</template>
