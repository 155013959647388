<template>
  <Modal
    v-if="sampleData"
    v-show="show"
    id="sample-modal"
    half
    titleClass="w-100"
    :stretch="!showCloseConfirmation"
    :shouldCloseOnShadeClick="shouldCloseOnShadeClick"
    :loading="loadingSample"
    :not-closable="showCloseConfirmation"
    @close="handleClose"
  >
    <SaveShortcut v-if="!saving" @save="save" />
    <template #header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="flex-grow-1">
          {{ templateTab?.title }}
        </div>
        <div class="d-flex align-items-center flex-wrap justify-content-end">
          <div
            v-if="
              !isReadOnly &&
              isOnline &&
              !hasUnsavedFiles &&
              !$refs['sample-form']?.needsToChooseAnApp
            "
            class="small clickable my-3 my-sm-0 text-end me-md-3"
            :class="autoSaveEnabled ? 'text-success' : 'text-warning'"
            @click="toggleAutoSave"
          >
            Auto Save {{ autoSaveEnabled ? 'Enabled' : 'Disabled' }}
            <i v-if="autoSaveEnabled" class="fal fa-check me-1"></i>
            <i v-else class="fal fa-exclamation-triangle me-1"></i>
          </div>
          <div
            v-if="!isReadOnly"
            class="text-xs text-end clickable me-3"
            :class="[
              hasChangedInputValues || !sampleId
                ? 'text-danger'
                : isOnline && !isCurrentUserOfflineSample
                ? 'text-success'
                : 'text-warning',
            ]"
            style="min-width: 9rem"
            @click.prevent="save"
          >
            <template v-if="saving"> Saving... </template>
            <template v-else-if="hasChangedInputValues || !sampleId">
              Unsaved changes
              <button class="clickable btn btn-sm btn-outline-primary ms-2">
                <i class="fal fa-save"></i>
              </button>
            </template>
            <template v-else-if="isOnline && !isCurrentUserOfflineSample">
              <i class="fal fa-cloud-upload-alt me-1"></i>
              Saved to Cloud
            </template>
            <template v-else>
              <i class="fal fa-exclamation-triangle me-1"></i>
              Saved Offline
            </template>
          </div>
        </div>
      </div>
    </template>

    <form style="display: contents" @submit.prevent>
      <div
        v-if="showCloseConfirmation"
        v-show="!converted.length"
        class="modal-body text-center py-5"
      >
        <h1 class="fal fa-exclamation" />
        <h6>Unsaved Changes!</h6>
        <div class="mt-3 d-flex flex-column align-items-center">
          <button
            class="btn btn-primary mb-2 py-2 fw-medium"
            style="width: 200px"
            @click="
              showCloseConfirmation = false;
              $nextTick(saveAndClose);
            "
          >
            Save and close
          </button>
          <button
            class="btn btn-outline-primary mb-4 py-2 fw-medium"
            style="width: 200px"
            @click="() => (showCloseConfirmation = false)"
          >
            Continue editing
          </button>
          <a
            href="#"
            @click.prevent="handleCloseConfirmation"
            class="fw-medium text-danger"
          >
            Discard
          </a>
        </div>
      </div>

      <template v-else-if="!converted.length">
        <div
          ref="sample-modal-body"
          id="sampleModal"
          @scroll="handleSampleModalBodyScroll"
        >
          <AlertBox v-if="hasUnsavedItem" type="danger"
            >This item is found to be unsaved. Its values have been restored.
            Please check carefully to ensure all the values are up to
            date.</AlertBox
          >

          <div
            class="d-flex flex-column flex-sm-row align-items-center justify-content-center w-100"
          >
            <div class="d-flex align-items-center flex-fill w-100">
              <input
                ref="sample-identifier"
                type="text"
                style="flex: 1 0"
                :class="[
                  'form-control',
                  {
                    'is-invalid': getFormErrorMessage('custom_title'),
                  },
                ]"
                :placeholder="
                  sampleTitlePlaceholder || 'Enter a sample title...'
                "
                :disabled="
                  !!templateTab?.has_read_only_item_titles || !templateTab
                "
                v-model="sampleIdentifier"
                @input="
                  saveToSession();
                  if (!hasChangedInputValues) {
                    hasChangedInputValues = true;
                  }
                "
              />
            </div>

            <div
              v-if="
                !isReadOnly &&
                !isAssigningShape &&
                (sampleId || sampleData.geojson)
              "
              class="btn-group btn-group-sm ms-2 mt-2 mt-md-0"
            >
              <template v-if="sampleId">
                <button
                  type="button"
                  :class="[
                    'btn btn-outline-secondary',
                    {
                      'btn-primary text-white': toggleSearch,
                    },
                  ]"
                  title="Search"
                  @click="toggleSearch = !toggleSearch"
                >
                  <i class="fal fa-search" />
                </button>
                <button
                  v-if="isAllowCollectionOnPoiAvailable"
                  type="button"
                  class="btn btn-outline-secondary"
                  @click="togglePoiCollection"
                >
                  <i class="fal fa-draw-polygon" />
                </button>
                <!--Move sample-->
                <button
                  v-if="sampleData.longitude && sampleData.latitude"
                  type="button"
                  class="btn btn-outline-secondary"
                  title="Edit shape"
                  :disabled="isLayerLocked"
                  @click="moveSample"
                >
                  <i class="fal fa-arrows-alt" />
                </button>
                <template v-else>
                  <!-- Assign a point -->
                  <button
                    v-if="
                      ['point', 'any'].includes(getTemplateTab()?.drawing_type)
                    "
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="handleAssignPoint"
                  >
                    <i class="fal fa-map-marker" />
                  </button>
                  <!-- Assign a polygon -->
                  <button
                    v-if="
                      ['polygon', 'any'].includes(
                        getTemplateTab()?.drawing_type
                      )
                    "
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="handleAssignPoly('polygon')"
                  >
                    <i class="fal fa-hexagon" />
                  </button>
                  <!-- Assign a polyline -->
                  <button
                    v-if="
                      ['polyline', 'any'].includes(
                        getTemplateTab()?.drawing_type
                      )
                    "
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="handleAssignPoly('polyline')"
                  >
                    <i class="fal fa-wave-triangle" />
                  </button>
                  <!-- Assign an arrow -->
                  <button
                    v-if="
                      ['arrow', 'any'].includes(getTemplateTab()?.drawing_type)
                    "
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="handleAssignPoly('arrow')"
                  >
                    <i class="fal fa-arrow-right" />
                  </button>
                  <!-- Assign a rectangle -->
                  <button
                    v-if="
                      ['rectangle', 'any'].includes(
                        getTemplateTab()?.drawing_type
                      )
                    "
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="handleAssignPoly('rectangle')"
                  >
                    <i class="fal fa-rectangle-wide" />
                  </button>
                  <!-- Assign a circle -->
                  <button
                    v-if="
                      ['circle', 'any'].includes(getTemplateTab()?.drawing_type)
                    "
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="handleAssignPoly('circle')"
                  >
                    <i class="fal fa-circle" />
                  </button>
                  <!-- Assign a hedge -->
                  <button
                    v-if="
                      ['hedge', 'any'].includes(getTemplateTab()?.drawing_type)
                    "
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="handleAssignPoly('hedge')"
                  >
                    Hedge
                  </button>
                </template>
                <button
                  v-if="!isEditing"
                  type="button"
                  class="btn btn-outline-secondary"
                  title="Duplicate"
                  :disabled="hasChangedInputValues"
                  @click="duplicateSample"
                >
                  <i v-if="!isDuplicating" class="fal fa-copy" />
                  <Spinner small v-else />
                </button>
                <button
                  v-if="canDeleteSample(sampleData.created_at)"
                  type="button"
                  class="btn btn-outline-danger"
                  :disabled="hasChangedInputValues || isLayerLocked"
                  @click="deleteSample"
                >
                  <i class="fal fa-trash-alt" />
                </button>
              </template>
              <PlainShapeToolbar
                v-else-if="sampleData.geojson"
                :isSearchOn="toggleSearch"
                :layer-model-id="sampleData.geojson.properties.layerId"
                @toggle-search="() => (toggleSearch = !toggleSearch)"
                @edit="handleEditPlainShape"
                @duplicate="handleDuplicatePlainShape"
                @delete="handleDeletePlainShape"
              />
            </div>
          </div>

          <div class="mt-2">
            <AlertBox v-if="isOnline && isOtherUserOfflineSample" type="danger">
              This item has been marked as offline, please contact
              <span class="fw-medium">
                {{ offlineUserEmail }}
              </span>
              to bring it back online.
            </AlertBox>

            <AlertBox
              v-if="isOnline && isCurrentUserOfflineSample"
              type="warning"
            >
              This item has been marked as offline,
              <span class="fw-medium">
                either resync or invalidate this sample in the offline manager
              </span>
              to bring this item back online.
            </AlertBox>

            <small
              v-if="sampleId && project.company_id === 82"
              class="text-muted d-block mb-3"
            >
              Your reference number for this record is: <b>{{ sampleId }}</b>
            </small>

            <SampleForm
              v-if="templateTabs"
              ref="sample-form"
              :sample="sampleData"
              :inputValues="inputValues"
              :templateTabs="templateTabs"
              :samples="sampleStore.samples"
              :isNonSpatialView="isNonSpatialView"
              :isNonSpatial="isNonSpatial"
              :toggleSearch="toggleSearch"
              :isOnline="isOnline"
              :isOfflineSample="
                !!(
                  isOtherUserOfflineSample ||
                  (isOnline ? isCurrentUserOfflineSample : false)
                )
              "
              :isReadOnly="isReadOnly"
              :sampleIdentifier="sampleIdentifier || sampleTitlePlaceholder"
              :has-changed-input-values="hasChangedInputValues"
              @isLoading="isLoading"
              @setSampleTitlePlaceholder="setSampleTitlePlaceholder"
              @setTemplateTabId="setTemplateTabId"
              @input="saveToSession"
              @clickCamera="clickCamera"
              @clickVideo="clickVideo"
              @clickSetPreview="clickSetPreview"
              @clickStartDrawing="clickStartDrawing"
              @subFolderChanged="onSubFolderChanged"
              @setIsReadOnly="isReadOnly = $event"
              @closeSearch="toggleSearch = false"
              @save="save"
            />
          </div>
        </div>
      </template>

      <template v-else>
        <AlertBox type="danger" class="mb-3" v-if="hasSaveFailureScreen">
          Saving failure occurred. Please check your internet connection and try
          again. If you continue having issues saving, please click back to app
          and take screenshots of your data and contact support.
        </AlertBox>

        <AlertBox class="mb-3" v-else>
          Please stay on this screen until all photos have processed and this
          pop up closes
        </AlertBox>

        <div
          v-for="(upload, i) in uploadProgress"
          :key="'upload-' + i"
          class="text-center"
        >
          <p>{{ upload.name }}</p>
          <div class="progress upload-progress mt-1">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              :class="{
                'bg-success': upload.progress == 100 && !upload.error.status,
                'bg-danger': upload.error.status,
              }"
              role="progressbar"
              :style="{
                width: upload.progress + '%',
              }"
              :aria-valuenow="upload.progress"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span v-if="!upload.error.status">{{ upload.progress }}%</span>
              <span v-else>Failed to upload</span>
            </div>
          </div>
          <p v-if="upload.error.status">
            {{ upload.error.status }} <br />
            <span v-if="upload.error.message">{{ upload.error.message }}</span>
          </p>
          <div v-if="upload.error.errors.length">
            <span v-for="(error, i) in upload.errors" :key="'error-' + i">
              {{ error }} <br />
            </span>
          </div>
          <hr />
        </div>
        <div
          v-if="showReturnToEdit || hasSaveFailureScreen"
          class="d-flex gap-3"
        >
          <button
            type="button"
            class="btn btn-outline-secondary flex-grow-1"
            @click="returnToEdit"
          >
            Return to edit
          </button>
          <button
            type="button"
            class="btn btn-outline-success flex-grow-1"
            @click="
              returnToEdit();
              $nextTick(saveAndClose);
            "
          >
            Try Again
          </button>
        </div>
      </template>

      <CameraUpload
        v-if="persistence.useCamera"
        @setImage="setAsset"
        @isLoading="isLoading"
        @close="setCloseCamera()"
      />
      <VideoUpload
        v-if="persistence.useVideo"
        @setVideo="setAsset"
        @isLoading="isLoading"
        @close="setCloseVideo()"
      />
      <ImagePreview
        v-if="persistence.usePreview"
        :src="persistence.previewFile.src"
        @close="setClosePreview()"
      />
      <DrawingModal
        v-model="showDrawing"
        :inputValue="inputValue"
        @setDrawing="setAsset"
        @setCloseDrawing="setCloseDrawing"
      />
    </form>
    <template #footer v-if="!showCloseConfirmation && !converted.length">
      <button
        type="button"
        class="btn btn-outline-secondary flex-grow-1"
        :disabled="collectionStore.isBusy || isCloseButtonDisabled"
        @click="handleClose"
      >
        <template v-if="isTracking"> Close </template>
        <template v-else-if="previousModal"> Back </template>
        <template v-else>Close</template>
      </button>

      <ButtonSpinner
        v-if="!isReadOnly && !isTracking"
        type="submit"
        class="btn btn-primary"
        style="flex: 1 0 60%"
        :isLoading="saving"
        :disabled="saving || collectionStore.isBusy || !templateTab"
        @click.native.prevent="saveAndClose"
      >
        <span v-if="!templateTab">No data to collect</span>
        <span v-else>Save and close</span>
      </ButtonSpinner>
    </template>
  </Modal>
</template>

<script>
import Modal from '@component-library/components/Modal.vue';
import { useOfflineStorageManagerStore } from '@component-library/store/offline-storage-manager';
import useSampleUploadManager from '@/js/composables/useSampleUploadManager';
import { canDeleteSample } from '@/js/helpers/general.js';
import useFigureStore from '@/js/stores/figure';
import useSampleStore from '@/js/stores/sample';
import {
  StackableModalType,
  checkIsSampleModal,
  getPreviousModal,
  getTopModal,
} from '@/js/types/modal-stack';
import auth from '@component-library/auth';
import * as cl_bl from '@component-library/business-logic';
import {
  findAppById,
  findAppByTitle,
  getLinkConfigs,
} from '@component-library/business-logic/app';
import { validateAppWithInputValues } from '@component-library/business-logic/gather-input-validation';
import {
  findInputValueByCompositeKey,
  getCompositeKey,
} from '@component-library/business-logic/input-value';
import { getNextItemPlaceholderTitle } from '@component-library/business-logic/item-identifiers';
import * as cl_bm from '@component-library/business-model';
import AlertBox from '@component-library/components/AlertBox.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import Spinner from '@component-library/components/Spinner.vue';
import DrawingModal from '@component-library/drawing/PaintingModal.vue';
import { getFieldAnchorId } from '@component-library/fields';
import { checkIsNewItem } from '@component-library/gather';
import { captureException, captureMessage } from '@component-library/sentry';
import SaveShortcut from '@component-library/shortcuts/SaveShortcut.vue';
import { useCollectionStore } from '@component-library/store/collection';
import { createFormContext } from '@component-library/utils';
import { waitFor } from '@component-library/utils/wait-for';
import { getSampleTitle } from '@maps/lib/olbm';
import LoadManager from '@maps/lib/olbm/common/LoadManager';
import { getLayerTitle } from '@maps/lib/olbm/layer/utils';
import { StylingPriority } from '@maps/lib/olbm/style/types';
import axios from 'axios';
import _debounce from 'lodash/debounce';
import { mapStores } from 'pinia';
import { inject } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import CameraUpload from './CameraUpload.vue';
import ImagePreview from './ImagePreview.vue';
import PlainShapeToolbar from './PlainShapeToolbar.vue';
import SampleForm from './SampleForm.vue';
import VideoUpload from './VideoUpload.vue';
import { useDialogStore } from '../../../../../../../component-library/store/dialog';

/** @deprecated Needs to be typescript vue composition API ASAP */
export default {
  name: 'SampleModal',
  components: {
    Modal,
    SampleForm,
    ButtonSpinner,
    CameraUpload,
    ImagePreview,
    VideoUpload,
    DrawingModal,
    AlertBox,
    PlainShapeToolbar,
    Spinner,
    SaveShortcut,
  },
  props: {
    value: Boolean,
    sampleData: Object,
    templateTabs: Array,
    isNonSpatialView: Boolean,
    updateField: Function,
    isAllowCollectionOnPoiAvailable: Boolean,
    isEditing: Boolean,
  },
  setup() {
    const map = inject('map');
    const isMapMounted = inject('isMapMounted');

    const figureStore = useFigureStore();
    const collectionStore = useCollectionStore();
    const { getLockedByFigure } = figureStore;

    return {
      map,
      isMapMounted,
      collectionStore,
      getLockedByFigure,
    };
  },
  data: () => ({
    toggleSearch: false,
    // True means this modal is visible.
    showConfirmation: true,
    showCloseConfirmation: false,
    previewSrc: null,
    loadingSample: false,
    sampleIdentifier: null,
    selectedTabId: null,
    inputValues: [],
    offlineUser: null,
    offlineUserId: null,
    saving: false,
    sampleTitlePlaceholder: null,
    hasChangedInputValues: false,
    isDuplicating: false,
    converted: [],
    uploadProgress: [],
    startedUpload: null,
    isReadOnly: false,
    formErrors: {},
    // Used to assign a shape to an item collected through a public form.
    isAssigningShape: false,
    isCloseButtonDisabled: false,
    hasSaveFailureScreen: false,
    autoSaveEnabled: false,
    autoSaveInterval: null,
    saveTimeout: null,
  }),
  provide() {
    return {
      formContext: createFormContext(
        cl_bm.common.FORM_CONTEXT_TYPE_GATHER,
        this.updateField,
        () => this.project,
        (appTitle) => {
          if (!this.previousModal || !checkIsSampleModal(this.previousModal)) {
            return;
          }
          const app = findAppByTitle(this.templateTabs, appTitle);
          if (!app) {
            return;
          }
          const linkConfigs = getLinkConfigs(app);
          if (linkConfigs.length === 0) {
            return;
          }
          const { payload: itemId } = this.previousModal;
          const item = this.sampleStore.findSampleById(itemId);
          const itemApp = findAppById(this.templateTabs, item.template_tab_id);
          return itemApp.title === appTitle ? item.id : undefined;
        },
        (isBusy) => {
          const loadManager = LoadManager.getInstance();
          if (isBusy) {
            loadManager.start();
          } else {
            loadManager.end();
          }
        }
      ),
      sampleModal: this,
    };
  },
  watch: {
    persistence(updated, outdated) {
      if (updated.useCamera != outdated.useCamera) {
        this.setShowConfirmation(!updated.useCamera);
      }
      if (updated.useVideo != outdated.useVideo) {
        this.setShowConfirmation(!updated.useVideo);
      }
      if (updated.usePreview != outdated.usePreview) {
        this.setShowConfirmation(!updated.usePreview);
      }
      if (updated.useDrawing != outdated.useDrawing) {
        this.setShowConfirmation(!updated.useDrawing);
      }
    },
    show() {
      this.showCheck();
    },
    inputValues: {
      async handler() {
        await waitFor(() => !!this.getTemplateTab());

        this.setSampleTitlePlaceholder();

        // TODO update input_values_for_styling_rules

        // Update sample's points_of_interest
        if (this.isAllowCollectionOnPoiAvailable) {
          const sampleId = this.sampleData.id ?? this.sampleData.sample_id;
          // This happens when restoring an unsaved item
          if (!sampleId) {
            return;
          }
          const pois = this.getPointsOfInterest();
          this.$emit('pois-change', {
            sampleId,
            pois,
          });
        }
      },
      deep: true,
    },
    sampleId() {
      this.hasChangedInputValues = false;
    },
    sampleData(newValue, oldValue) {
      this.sampleTitlePlaceholder = null;
      if (newValue && (!oldValue || newValue.id !== oldValue.id)) {
        this.loadSample();
      }
    },
    sampleTitlePlaceholder(newValue) {
      this.updatePersistence({
        sampleIdentifier: this.sampleIdentifier || newValue,
      });
    },
    autoSaveEnabled(newValue) {
      localStorage.setItem('autoSaveEnabled', newValue);
    },
  },
  computed: {
    ...mapState({
      project: (state) => state.project,
      isOnline: (state) => state.isOnline,
      modalStack: (state) => state.modalStack,
      hasUnsavedItem: (state) => state.hasUnsavedItem,
      previousModal() {
        const topModal = getTopModal(this.modalStack);
        return topModal
          ? getPreviousModal(this.modalStack, topModal)
          : undefined;
      },
      isOtherUserOfflineSample() {
        return (
          this.offlineUserId && this.offlineUserId !== auth.user()?.user_id
        );
      },
      isCurrentUserOfflineSample() {
        return (
          this.offlineUserId && this.offlineUserId === auth.user()?.user_id
        );
      },
      offlineUserEmail() {
        return this.offlineUser?.email || 'an admin';
      },
    }),
    ...mapGetters({
      persistence: 'get_persistence',
    }),
    ...mapStores(useSampleStore, useOfflineStorageManagerStore),
    showDrawing: {
      get() {
        return this.persistence.useDrawing;
      },
      set(updated) {
        this.updatePersistence({
          useDrawing: !!updated,
        });
      },
    },
    drawing: {
      get() {
        return this.persistence.drawing;
      },
      set(updated) {
        this.updatePersistence({
          drawing: updated,
        });
      },
    },
    inputValue() {
      const inputValue = this.inputValues[this.persistence?.inputIndex] || [];
      return inputValue || false;
    },
    show: {
      get() {
        return this.value;
      },
      set(updated) {
        this.$emit('input', updated);
      },
    },
    sampleId() {
      return this.sampleData?.id ?? null;
    },
    layerModelId() {
      return this.sampleData?.geojson?.properties.layerId;
    },
    isLayerLocked() {
      const layerModelId = this.sampleData?.project_figure_layer_id;
      return typeof layerModelId === 'number'
        ? !!this.getLockedByFigure(layerModelId)
        : false;
    },
    shouldCloseOnShadeClick() {
      return !this.hasChangedInputValues;
    },
    isNonSpatial() {
      return (
        !this.sampleData.geojson &&
        !this.sampleData.area_figure_layer &&
        !this.sampleData.layer &&
        !this.sampleData.latlng &&
        !this.sampleData.longitude &&
        !this.sampleData.latitude
      );
    },
    isTracking() {
      return this.persistence.isTracking;
    },
    hasTemplateTab() {
      return (
        !!this.sampleData?.template_to_select ||
        !!this.sampleData?.template_tab_id
      );
    },
    templateTab() {
      return this.templateTabs.find(
        (tab) =>
          tab.id === this.selectedTabId ||
          tab.id == this.sampleData?.template_tab_id ||
          tab.id == this.sampleData?.template_to_select
      );
    },
    // A plain shape is a polygon, or a rectangle, circle, polyline, arrow, hedge without an app assigned.
    plainShapeTitle() {
      if (!this.layerModelId) {
        return undefined;
      }

      const layerModel = this.map.findLayerModelById(this.layerModelId);
      return layerModel && getLayerTitle(layerModel);
    },
    showReturnToEdit() {
      return (
        this.uploadProgress.find((item) => item.error.status) ||
        (this.startedUpload ? new Date() - this.startedUpload > 15000 : false)
      );
    },
    hasUnsavedFiles() {
      const requestInputValues = this.inputValues.filter(
        (v) =>
          (v.value != null || v.value2 != null) &&
          v.template_tab_id == this.templateTab?.id
      );
      for (let inputValue of requestInputValues) {
        if (inputValue.value instanceof Blob) {
          return true;
        }

        if (Array.isArray(inputValue.value) && inputValue.value.length > 0) {
          for (let [index, aFile] of inputValue.value.entries()) {
            if (aFile?.blob && aFile.blob instanceof Blob) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions([
      'updatePersistence',
      'pushToModalStack',
      'popFromModalStack',
      'updateTopModalPayload',
      'setHasUnsavedItem',
    ]),
    setTemplateTabId(id) {
      this.selectedTabId = id;
    },
    showCheck() {
      if (!this.showConfirmation) {
        return;
      }

      if (!this.show) {
        window.onbeforeunload = null;
        return;
      }

      window.onbeforeunload = (e) => {
        if (!this.hasChangedInputValues) {
          return;
        }

        const message = 'Changes you made may not be saved';

        //old browsers
        if (e) {
          e.returnValue = message;
        }

        //safari, chrome(chrome ignores text)
        return message;
      };

      // 19/02/24 by MG: The following line should be redundant because sample loading
      // is handled by the sampleData watcher
      // this.loadSample();

      if (this.hasTemplateTab) {
        waitFor(() => this.getTemplateTab()).then(() => {
          if (this.$refs['sample-modal-body']) {
            this.$refs['sample-modal-body'].scrollTop = 0;
          }

          if (this.$refs['sample-identifier']) {
            this.$refs['sample-identifier'].focus();
          }

          this.setSampleTitlePlaceholder();
          this.$forceUpdate();

          if (
            !(this.sampleData.id || this.sampleData.sample_id) &&
            this.templateTab.sections.length === 0 &&
            this.templateTab.has_read_only_item_titles
          ) {
            this.save().then(() => {
              this.$toastStore.info('App has no custom fields to edit');
              this.close();
            });
          }
        });
      }
    },
    returnToEdit() {
      this.saving = false;
      this.loadingSample = false;
      this.converted = [];
      this.uploadProgress = [];
      this.assetErrors = [];
    },
    setAsset({ src, blob, type }, json = null) {
      try {
        const inputIndex = this.persistence.inputIndex;
        if (inputIndex === -1) return;
        const inputValue = this.inputValues[inputIndex];
        const name = `asset-${
          this.inputValues.length +
          (Array.isArray(inputValue.value) ? inputValue.value.length : 0)
        }.${type}`;
        let value = null;
        let value2 = null;
        if ((type == 'webp' || type == 'png') && json == null) {
          value = Array.isArray(inputValue.value)
            ? [...inputValue.value, { src, blob, name }]
            : [{ src, blob, name }];
        } else {
          value = blob;
          value2 = name;
        }
        if (json) value2 = json;
        this.$set(this.inputValues, inputIndex, {
          ...inputValue,
          value,
          value2,
        });
        this.saveToSession();
        this.$nextTick(() => {
          this.isLoading(false);
        });
      } catch (e) {
        this.isLoading(false);
        throw e;
      }
    },
    clickCamera({ useCamera, inputIndex }) {
      this.updatePersistence({
        useCamera,
        inputIndex,
      });
    },
    clickVideo({ useVideo, inputIndex }) {
      this.updatePersistence({
        useVideo,
        inputIndex,
      });
    },
    clickSetPreview(file = null) {
      this.updatePersistence({
        usePreview: !!file,
        previewFile: file,
      });
    },
    clickStartDrawing({ useDrawing, inputIndex }) {
      this.updatePersistence({
        useDrawing,
        inputIndex,
      });
    },
    setCloseCamera() {
      this.updatePersistence({
        useCamera: false,
        assetFile: null,
      });
    },
    setCloseVideo() {
      this.updatePersistence({
        useVideo: false,
        assetFile: null,
      });
    },
    setClosePreview() {
      this.updatePersistence({
        usePreview: false,
        previewFile: null,
      });
    },
    setCloseDrawing() {
      this.updatePersistence({
        useDrawing: false,
        assetFile: null,
      });
    },
    setShowConfirmation(value = true) {
      if (value) {
        this.$emit('open');
        this.$nextTick(() => {
          this.showConfirmation = true;
        });
      } else {
        this.showConfirmation = false;
        this.close(false);
      }
    },
    isLoading(value) {
      this.saving = value;
    },
    getTemplateTab() {
      return this.$refs['sample-form']?.getTemplateTab();
    },
    clear() {
      this.isAssigningShape = false;
      this.inputValues = [];
      this.sampleIdentifier = null;
      this.sampleTitlePlaceholder = null;
      this.$root.$emit('setTemplateToSelect', null);
      this.$root.$emit('setSampleDataToModify', null);
      this.clearFromSession();
      this.map?.clearClickedPoi();
    },
    close(clear = true) {
      this.$emit('close', clear);
    },
    async uploadAsset(
      requestInputValues,
      blob,
      inputValue,
      index = null,
      name = false
    ) {
      let data = new FormData();
      let isJSON = false;
      // ! Only a check, will fail if not JSON but needs to continue
      try {
        if (JSON.parse(inputValue.value2)) {
          isJSON = true;
        }
      } catch (e) {}
      name = isJSON ? 'drawing.png' : name || inputValue.value2;

      this.uploadProgress.push({
        inputValue,
        name,
        progress: 0,
        error: {
          status: null,
          message: null,
          errors: [],
        },
      });
      let progressIndex = this.uploadProgress.length - 1;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (e) => {
          if (e.lengthComputable) {
            this.uploadProgress[progressIndex].progress = Math.round(
              (e.loaded * 100) / e.total
            );
          }
        },
      };
      data.append('file', blob);
      data.append('project_id', this.project.project_id);

      try {
        const response = await axios.post(`/api/project/asset`, data, config);
        const result = { src: response.data.src, inputValue, index };
        let existing = requestInputValues.find(
          (inputValue) => inputValue == result?.inputValue
        );
        if (!result.index && result.index != 0) {
          existing.value = result.src;
          return existing;
        }
        existing.value[result.index].src = result.src;
        existing.value[result.index].blob = null;
        return existing;
      } catch (error) {
        captureException(error);
        if (error.response) {
          if (error.response.status) {
            this.uploadProgress[
              progressIndex
            ].error.status = `${error.response.status} - ${error.response.statusText}`;
          }
          if (error.response.data.message) {
            this.uploadProgress[progressIndex].error.message =
              error.response.data.message;
          }
        } else {
          this.uploadProgress[progressIndex].error.status = error;
        }
        let existing = requestInputValues.find((input) => input == inputValue);
        if (!index && index != 0) {
          return existing;
        }
        return false;
      }
    },
    checkAndReportDuplicateInputs() {
      const inputValuesMap = {};
      this.inputValues.forEach((inputValue) => {
        const key = `${inputValue.sample_id}-${inputValue.template_field_id}-${inputValue.template_section_index}`;
        if (inputValuesMap[key]) {
          const errorMessage = `Duplicate input value with ids (${inputValue.id},${inputValuesMap[key]}) found with
            sample id: ${inputValue.sample_id}, template field id: ${inputValue.template_field_id}, template section index: ${inputValue.template_section_index}`;
          console.error(errorMessage);
          captureException(new Error(errorMessage));
        } else {
          inputValuesMap[key] = inputValue.id;
        }
      });
    },
    saveAndClose() {
      this.save().then((result) => {
        if (result === false) {
          return;
        }

        this.close();
      });
    },
    async save(autoSave = false) {
      if (this.saving) {
        return;
      }
      // Only when explicitly true, incase a $event is passed in by @input etc.
      autoSave = autoSave === true;
      this.hasSaveFailureScreen = false;
      const isNewItem = checkIsNewItem(this.sampleData);
      this.checkAndReportDuplicateInputs();
      const templateTab = this.getTemplateTab();
      const templateTabId = templateTab?.id;
      if (!templateTabId) {
        if (!this.isOnline) {
          this.$toastStore.error(
            'This project is not available offline, please check your connection.'
          );
          captureMessage('Template tab not found during save while offline');
          return false;
        }
        this.$toastStore.error('Please try again in a few seconds.');
        captureMessage('Template tab not found during save');
        return false;
      }

      const validation = validateAppWithInputValues(
        templateTab,
        this.inputValues
      );
      if (!validation.isValid()) {
        this.$toastStore.error(
          validation.getFirstError() ?? 'There are invalid fields.'
        );
        const fieldLabel = validation.getFirstFieldLabel();
        const fieldAnchorId = getFieldAnchorId(fieldLabel);
        const fieldAnchorEl = document.getElementById(fieldAnchorId);
        if (!autoSave) {
          fieldAnchorEl.scrollIntoView();
        }
        return false;
      }

      const hasFieldsToFill =
        this.templateTabs
          .find((t) => t.id == templateTabId)
          .sections.filter((s) => !s.validated).length > 0;

      this.$root.$emit('checkSectionValidation');

      if (hasFieldsToFill) {
        this.$toastStore.error(
          'There are required fields that still need filling.'
        );
        return false;
      }

      this.clearFromSession();
      try {
        this.saving = true;
        this.startedUpload = new Date();

        if (
          !this.isNonSpatial &&
          !this.sampleData.latlng &&
          this.sampleData.latitude
        ) {
          this.sampleData.latlng = {
            lat: this.sampleData.latitude,
            lng: this.sampleData.longitude,
          };
        }

        let areaGeojson = null;
        if (!this.isNonSpatial) {
          areaGeojson = this.sampleData.geojson
            ? this.sampleData.geojson
            : null;

          // update to match new template colour
          if (!areaGeojson && this.sampleData.area_figure_layer) {
            areaGeojson = this.sampleData.area_figure_layer.geojson;
          }

          if (
            areaGeojson &&
            areaGeojson.properties.stylingPriority !== StylingPriority.Custom
          ) {
            areaGeojson = {
              ...areaGeojson,
              properties: {
                ...areaGeojson.properties,
                color:
                  templateTab.drawing_type != 'any'
                    ? templateTab.drawing_colour
                    : '#3388ff',
                ...(templateTab.drawing_properties || {}),
              },
            };
          }

          const startNodeFieldAndValue =
            this.getLineNodeFieldAndValue('start_node_field');
          const endNodeFieldAndValue =
            this.getLineNodeFieldAndValue('end_node_field');

          if (this.getLineNodeFieldIds().length > 0) {
            areaGeojson = {
              ...areaGeojson,
              properties: {
                ...areaGeojson.properties,
                arrowHeads: [
                  ...(areaGeojson.properties?.arrowHeads || []).filter(
                    (a) => !this.getLineNodeFieldIds().includes(a.fieldId)
                  ),
                  ...(startNodeFieldAndValue ? [startNodeFieldAndValue] : []),
                  ...(endNodeFieldAndValue ? [endNodeFieldAndValue] : []),
                ],
              },
            };
          }

          if (
            areaGeojson &&
            (this.sampleData.id || isNaN(areaGeojson.properties.layerId))
          ) {
            delete areaGeojson.properties.layerId;
          }
        }

        const requestInputValues = this.inputValues.filter(
          (v) =>
            (v.value != null || v.value2 != null) &&
            v.template_tab_id == templateTab.id
        );

        requestInputValues.forEach((item, index, arr) => {
          let parsed = JSON.parse(JSON.stringify(item));
          arr[index] = { ...parsed, value: item.value };
        });

        if (!this.isOnline) {
          await this.saveSample(templateTab, areaGeojson, requestInputValues);
          return;
        }

        this.uploadProgress = [];

        for (let inputValue of requestInputValues) {
          if (inputValue.value instanceof Blob) {
            this.converted.push(
              this.uploadAsset(requestInputValues, inputValue.value, inputValue)
            );
            continue;
          }

          if (Array.isArray(inputValue.value) && inputValue.value.length > 0) {
            for (let [index, aFile] of inputValue.value.entries()) {
              if (aFile?.blob && aFile.blob instanceof Blob) {
                this.converted.push(
                  this.uploadAsset(
                    requestInputValues,
                    aFile.blob,
                    inputValue,
                    index,
                    aFile.name
                  )
                );
              }
            }
          }
        }

        const response = await Promise.all(this.converted);
        this.hasSaveFailureScreen =
          response.includes(false) && this.converted?.length;
        if (this.hasSaveFailureScreen) {
          this.$toastStore.error('Unable to save all assets.');
        }

        await this.saveSample(
          templateTab,
          areaGeojson,
          requestInputValues,
          this.sampleData,
          false
        );

        if (this.hasSaveFailureScreen) {
          return false;
        }
        this.loadingSample = false;
        this.hasChangedInputValues = false;
        this.converted = [];
        this.uploadProgress = [];
        this.startedUpload = null;
      } finally {
        if (this.hasSaveFailureScreen) {
          return false;
        }
        if (isNewItem) {
          this.updateTopModalPayload(this.sampleData.id);
        }
        this.saving = false;
      }
    },

    async saveSample(
      templateTab,
      areaGeojson,
      input_values,
      sampleData = this.sampleData,
      isDuplicating = false
    ) {
      this.formErrors = {};

      let customTitle = this.sampleIdentifier || this.sampleTitlePlaceholder;
      if (isDuplicating) {
        customTitle = `${customTitle} - copy`;
      }
      const clickedPoi = this.map?.getClickedPoi();

      try {
        const { saveSampleAndValues } = useSampleUploadManager(this.project);

        const { sample, area_figure_layer, samples_count } =
          await saveSampleAndValues(
            {
              template_tab_id: templateTab.id,
              sample_id: sampleData.id ?? sampleData.sample_id,
              custom_title: customTitle,
              latitude: this.isNonSpatial ? null : sampleData.latlng.lat,
              longitude: this.isNonSpatial ? null : sampleData.latlng.lng,
              icon_opacity: sampleData.icon_opacity,
              icon_opacity_override: sampleData.icon_opacity_override,
              area_geojson: areaGeojson,
              input_values,
              sub_folder: sampleData.sub_folder,
            },
            input_values,
            this.$root
          );

        if (!isDuplicating) {
          this.sampleIdentifier ||= customTitle;
          this.sampleData.id = sample.id;
        }

        if (samples_count !== undefined) {
          this.$root.$emit('updateTemplateSampleCount', {
            id: templateTab.id,
            samples_count,
          });
        }

        if (area_figure_layer) {
          await new Promise((resolve) =>
            this.$root.$emit('addAreaFigureLayer', {
              areaFigureLayer: area_figure_layer,
              afterLayerId: sampleData.after_layer_id || null,
              done: resolve,
            })
          );
        }

        if (
          !this.isNonSpatialView &&
          (!sampleData?.id || !this.map.findSampleById(sampleData.id))
        ) {
          this.$root.$emit('createNewSample', sample);
        } else {
          this.$root.$emit('updateSample', sample);
        }

        if (area_figure_layer) {
          this.$root.$emit('updateGeojson', {
            sample,
            geojson: area_figure_layer.geojson,
          });
        }

        this.setImageSrc(input_values);

        if (isDuplicating) {
          this.$root.$emit('setSampleDataToModify', {
            ...sample,
            duplicating: true,
          });
        } else {
          this.$root.$emit('setSampleDataToModify', { ...sample });
        }

        this.$root.$emit('onSaveSampleFinish', { sample, clickedPoi });

        this.hasChangedInputValues = false;
        return sample.id;
      } catch (err) {
        if (err.response && err.response.data) {
          if (!err.response.data.errors) {
            this.$toastStore.error(
              'Something went wrong with saving, try again.'
            );
          } else {
            this.formErrors = err.response.data.errors;
          }
        }
        throw err;
      } finally {
        this.saving = false;
      }
    },
    async loadSample() {
      this.offlineUser = null;
      this.offlineUserId = null;
      this.sampleIdentifier = null;

      if (this.sampleId) {
        this.loadingSample = true;

        try {
          const { data } = await axios.get(
            `/api/project/sample/values/${this.sampleId}`
          );
          const { sample, input_values } = data;
          this.sampleIdentifier = getSampleTitle(sample);
          const { offline_user: offlineUser, offline_user_id: offlineUserId } =
            sample;
          if (offlineUser) {
            this.offlineUser = offlineUser;
          }
          if (offlineUserId) {
            this.offlineUserId = offlineUserId;
          }
          this.setImageSrc(input_values);
        } catch (e) {
          this.sampleData.id = undefined;
          throw e;
        } finally {
          this.loadingSample = false;
        }
      } else {
        // Required when assigning an app to geometries such as rectangle created in Maps
        if (this.sampleData?.geojson) {
          const { title } = this.sampleData.geojson.properties;
          this.sampleIdentifier = title;
        }
      }

      if (this.hasUnsavedItem) {
        const { sampleIdentifier, sampleTabId, inputValues } = this.persistence;

        if (sampleIdentifier) {
          this.sampleIdentifier = sampleIdentifier;
        }

        if (sampleTabId) {
          this.setSampleTab(sampleTabId);
        }

        if (inputValues.length > 0) {
          for (const iv of inputValues) {
            const existingIv = findInputValueByCompositeKey(
              this.inputValues,
              getCompositeKey(iv)
            );
            if (existingIv) {
              const index = this.inputValues.indexOf(existingIv);
              this.inputValues.splice(index, 1, iv);
            } else {
              this.inputValues.push(iv);
            }
            if (!this.hasChangedInputValues) {
              this.hasChangedInputValues = true;
            }
          }
        }
      }

      this.$emit('sample-loaded');
    },
    setImageSrc(input_values) {
      this.inputValues = input_values.map((inputValue) => {
        try {
          let jsonValue = JSON.parse(inputValue.value);
          if (jsonValue && Array.isArray(jsonValue)) {
            inputValue.value = jsonValue.map((aValue) => {
              if (aValue.src) {
                aValue.src = `/api/images/value/${this.project.project_id}/${aValue.src}`;
              }
              return aValue;
            });
          }
        } catch (e) {}

        return inputValue;
      });
    },
    saveToSession() {
      this.updatePersistence({
        sampleIdentifier: this.sampleIdentifier || this.sampleTitlePlaceholder,
        sampleData: this.sampleData,
        sampleTabId:
          this.sampleData?.template_to_select ??
          this.sampleData?.template_tab_id ??
          this.$refs['sample-form']?.dataTabSelected,
        inputValues: this.inputValues,
      });
    },
    clearFromSession() {
      this.updatePersistence({
        sampleIdentifier: null,
        sampleData: null,
        sampleTabId: null,
        inputValues: [],
        projectId: null,
        projectTitle: null,
      });
      this.setHasUnsavedItem(false);
    },
    async moveSample() {
      const sampleData = this.sampleData;
      const { id, area_figure_layer, geojson, duplicating } = sampleData;

      this.close(false);

      if (!area_figure_layer && !geojson) {
        this.$root.$emit('goToSampleOnMap', sampleData);
        // The sample could be out of viewport so need to wait for the sample to be loaded.
        await waitFor(() => !!this.map.findSampleById(id));
        const lm = this.map.getLayerManager();
        const feature = lm.findSampleFeatureById(id);
        await lm.hideFeature(feature);
      }

      this.$root.$emit('modifySampleLocation', {
        ...sampleData,
        duplicating,
      });
    },
    togglePoiCollection() {
      this.moveSample();
      this.$root.$emit('togglePoiEditing', true);
    },
    async duplicateSample() {
      this.isDuplicating = true;

      try {
        if (!this.sampleData.area_figure_layer && !this.isNonSpatial) {
          this.close(false);
          this.$root.$emit('setSampleDataToModify', this.sampleData);
          this.$root.$emit('startDuplicateSampleMovement');
          return;
        }

        if (this.isNonSpatial) {
          this.close(false);

          try {
            const response = await axios.post('api/project/sample/duplicate', {
              sample_id: this.sampleData.id,
            });

            const sample = response.data.sample;
            this.sampleStore.addSample(sample);

            this.$root.$emit('setSampleDataToModify', sample);
            this.$emit('open');
          } catch (error) {
            this.close();
            this.$toastStore.error('Failed to duplicate item, try again.');
            this.$root.$emit('setSampleDataToModify', null);
            console.error(error);
          } finally {
            return;
          }
        }

        const templateTab = this.getTemplateTab();
        const duplicatedSample = structuredClone(this.sampleData);
        delete duplicatedSample.id;

        if (duplicatedSample?.area_figure_layer?.id) {
          delete duplicatedSample.area_figure_layer.id;
        }

        if (!this.sampleData.latlng && this.sampleData.latitude) {
          duplicatedSample.latlng = {
            lat: this.sampleData.latitude,
            lng: this.sampleData.longitude,
          };
        }

        let areaGeojson = duplicatedSample.geojson
          ? duplicatedSample.geojson
          : duplicatedSample.geoJson
          ? duplicatedSample.geoJson
          : duplicatedSample.layer
          ? duplicatedSample.layer.toGeoJSON()
          : null;

        if (!areaGeojson && duplicatedSample.area_figure_layer) {
          areaGeojson = duplicatedSample.area_figure_layer.geojson;
        }

        if (areaGeojson) {
          delete areaGeojson.id;
          delete areaGeojson.properties.title;
          delete areaGeojson.properties.layerUid;
        }

        duplicatedSample.duplicate_of_id = this.sampleData.id;

        const requestInputValues = this.inputValues.filter(
          (v) =>
            (v.value != null || v.value2 != null) &&
            v.template_tab_id == templateTab.id
        );

        requestInputValues.forEach((item, index, arr) => {
          let parsed = JSON.parse(JSON.stringify(item));
          arr[index] = { ...parsed, value: item.value, sample_id: null };
        });

        const sampleId = await this.saveSample(
          templateTab,
          areaGeojson,
          requestInputValues,
          duplicatedSample,
          true
        );
        if (sampleId) {
          this.moveSample();
        } else {
          this.close();
        }
      } finally {
        this.isDuplicating = false;
      }
    },
    deleteSample() {
      this.$emit('showDeleteSampleModal');
    },
    canDeleteSample(created_at) {
      return canDeleteSample(created_at);
    },
    setSampleTitlePlaceholder() {
      const templateTab = this.getTemplateTab();

      if (!templateTab) {
        return;
      }

      // The map is null when this modal shows in Non-spatial view.
      const clickedPoi = this.isMapMounted
        ? this.map.getClickedPoi()
        : undefined;
      if (clickedPoi) {
        const sample = this.map.findSampleById(clickedPoi.sampleId);
        const title = getSampleTitle(sample);
        this.sampleTitlePlaceholder = `${title}-${templateTab.title}-${
          clickedPoi.index + 1
        }`;
        return;
      }

      const { item_title_field_id: itemTitleFieldId } = templateTab;
      if (!itemTitleFieldId) {
        this.sampleTitlePlaceholder = getNextItemPlaceholderTitle(
          templateTab,
          this.sampleStore.findSamplesByAppId(templateTab.id).length + 1
        );
      }

      // Don't want to overwrite the sampleIdentifier if it's already set
      if (this.sampleIdentifier) {
        return;
      }

      const inputValues = this.inputValues.filter(
        (iv) => iv.template_field_id === itemTitleFieldId
      );
      inputValues.sort(
        (iv1, iv2) => iv1.template_section_index - iv2.template_section_index
      );

      if (inputValues.length > 0) {
        const inputValue = inputValues[0];
        this.sampleIdentifier = cl_bl.input_value.formatInputValueAsItemTitle(
          inputValue,
          cl_bl.template_tab.getField(templateTab, inputValue.template_field_id)
        );
      } else {
        this.sampleIdentifier = '';
      }
    },
    setSampleTab(tabId) {
      if (this.sampleData) {
        this.$set(this.sampleData, 'template_tab_id', tabId);
      }
    },
    async handleClose() {
      if (this.isReadOnly) {
        this.close();
        return;
      }

      if (this.isTracking) {
        this.close(false);
        return;
      }

      if (this.hasChangedInputValues) {
        this.showCloseConfirmation = true;
      } else {
        this.handleCloseConfirmation();
      }
    },
    handleCloseConfirmation() {
      if (this.isEditing) {
        this.$root.$emit('cancelDraw');
      }

      this.hasChangedInputValues = false;
      this.showCloseConfirmation = false;
      this.close();
    },
    gotoItem(item) {
      const payload =
        typeof item === 'number' ? item : 'id' in item ? item.id : undefined;
      if (payload === undefined) {
        return;
      }
      this.pushToModalStack({
        type: StackableModalType.SampleModal,
        payload,
      });
    },
    onSubFolderChanged(value) {
      this.$emit('update:sampleData', {
        ...this.sampleData,
        sub_folder: value,
      });
    },
    getPointsOfInterest() {
      const app = this.getTemplateTab();
      if (!app) {
        return [];
      }

      return cl_bl.input_value.getPointsOfInterest(app, this.inputValues);
    },
    getPointOfInterestInputValueLocator() {
      if (!this.isAllowCollectionOnPoiAvailable) {
        throw 'The feature [Allow collection on point of interest] is not available';
      }

      const app = this.getTemplateTab();
      const section = app.sections.find(
        (section) => section.system_reference === 'point_of_interest'
      );
      if (!section) {
        throw 'The section [point_of_interest] was not found';
      }

      const dataFormField = section.template_fields.find(
        (field) => field.system_reference === 'data_form'
      );
      if (!dataFormField) {
        throw `The DataForm field was not found in the section: section id is ${section.id}.`;
      }

      const longitudeField = section.template_fields.find(
        (field) => field.system_reference === 'longitude'
      );
      if (!longitudeField) {
        throw `The Longitude field was not found in the section: section id is ${section.id}.`;
      }

      const latitudeField = section.template_fields.find(
        (field) => field.system_reference === 'latitude'
      );
      if (!latitudeField) {
        throw `The Latitude field was not found in the section: section id is ${section.id}.`;
      }

      return { app, section, dataFormField, longitudeField, latitudeField };
    },
    addPointOfInterest(poi) {
      const { app, section, dataFormField, longitudeField, latitudeField } =
        this.getPointOfInterestInputValueLocator();

      const pois = this.getPointsOfInterest();
      let templateSectionIndex = pois.findIndex(
        (poi) => poi.longitude === null || poi.latitude === null
      );
      if (templateSectionIndex === -1) {
        templateSectionIndex = pois.length;
      }

      const pendingInputValues = [
        {
          template_tab_id: app.id,
          template_field_id: dataFormField.id,
          template_section_id: section.id,
          template_section_index: templateSectionIndex,
          value: poi.dataForm,
        },
        {
          template_tab_id: app.id,
          template_field_id: longitudeField.id,
          template_section_id: section.id,
          template_section_index: templateSectionIndex,
          value: poi.longitude,
        },
        {
          template_tab_id: app.id,
          template_field_id: latitudeField.id,
          template_section_id: section.id,
          template_section_index: templateSectionIndex,
          value: poi.latitude,
        },
      ];
      for (let pendingInputValue of pendingInputValues) {
        const index = this.inputValues.findIndex(
          (iv) =>
            iv.template_tab_id === pendingInputValue.template_tab_id &&
            iv.template_field_id === pendingInputValue.template_field_id &&
            iv.template_section_id === pendingInputValue.template_section_id &&
            iv.template_section_index ===
              pendingInputValue.template_section_index
        );
        pendingInputValue = {
          ...pendingInputValue,
          id: null,
          project_id: this.project.project_id,
          sample_id: this.sampleData.id,
          value2: null,
          deleted_at: null,
          created_at: null,
          updated_at: null,
        };
        if (index === -1) {
          this.inputValues.push(pendingInputValue);
        } else {
          this.inputValues.splice(index, 1, pendingInputValue);
        }
      }
      this.saveToSession();
    },
    changeLonLatOfPointOfInterest(index, lonLat) {
      const { app, section, longitudeField, latitudeField } =
        this.getPointOfInterestInputValueLocator();
      const { longitude, latitude } = lonLat;
      const pendingInputValues = [
        {
          template_tab_id: app.id,
          template_field_id: longitudeField.id,
          template_section_id: section.id,
          template_section_index: index,
          value: longitude,
        },
        {
          template_tab_id: app.id,
          template_field_id: latitudeField.id,
          template_section_id: section.id,
          template_section_index: index,
          value: latitude,
        },
      ];
      for (let pendingInputValue of pendingInputValues) {
        const inputValueIndex = this.inputValues.findIndex(
          (iv) =>
            iv.template_tab_id === pendingInputValue.template_tab_id &&
            iv.template_field_id === pendingInputValue.template_field_id &&
            iv.template_section_id === pendingInputValue.template_section_id &&
            iv.template_section_index ===
              pendingInputValue.template_section_index
        );
        pendingInputValue = {
          ...this.inputValues[inputValueIndex],
          ...pendingInputValue,
        };
        this.inputValues.splice(inputValueIndex, 1, pendingInputValue);
      }
      this.saveToSession();
    },
    deletePointOfInterest(index) {
      const { app, section, dataFormField, longitudeField, latitudeField } =
        this.getPointOfInterestInputValueLocator();
      const pendingInputValues = [
        {
          template_tab_id: app.id,
          template_field_id: dataFormField.id,
          template_section_id: section.id,
          template_section_index: index,
        },
        {
          template_tab_id: app.id,
          template_field_id: longitudeField.id,
          template_section_id: section.id,
          template_section_index: index,
        },
        {
          template_tab_id: app.id,
          template_field_id: latitudeField.id,
          template_section_id: section.id,
          template_section_index: index,
        },
      ];
      for (let pendingInputValue of pendingInputValues) {
        const inputValueIndex = this.inputValues.findIndex(
          (iv) =>
            iv.template_tab_id === pendingInputValue.template_tab_id &&
            iv.template_field_id === pendingInputValue.template_field_id &&
            iv.template_section_id === pendingInputValue.template_section_id &&
            iv.template_section_index ===
              pendingInputValue.template_section_index
        );
        if (inputValueIndex !== -1) {
          this.inputValues.splice(inputValueIndex, 1);
        }
      }

      // Adjust the template_section_index of pois whose index is greater than deleted poi's index.
      this.inputValues = this.inputValues.map((iv) => {
        const { template_section_index } = iv;
        if (template_section_index > index) {
          iv = {
            ...iv,
            template_section_index: template_section_index - 1,
          };
        }
        return iv;
      });
      this.saveToSession();
    },
    getFormErrorMessage(key) {
      const indexedError = this.formErrors[key];
      return indexedError ? indexedError[0] : null;
    },
    handleEditPlainShape(id) {
      this.$root.$emit('editPlainShape', id);
      this.close(false);
    },
    handleDuplicatePlainShape(id) {
      this.$root.$emit('duplicatePlainShape', id);
      this.close(false);
    },
    handleDeletePlainShape(id) {
      this.$emit('showDeletePlainShapeModal', id);
    },
    handleAssignPoint() {
      this.isAssigningShape = true;
      this.$root.$emit('startDrawMarker', {
        tabId: this.sampleData.template_tab_id,
        title: getSampleTitle(this.sampleData),
      });
      this.close(false);
    },
    handleAssignPoly(drawingType) {
      this.isAssigningShape = true;
      this.$root.$emit('startDrawPoly', {
        tabId: this.sampleData.template_tab_id,
        drawingType,
      });
      this.close(false);
    },
    getLineNodeFieldIds() {
      const templateTab = this.getTemplateTab();
      if (!templateTab) {
        return [];
      }

      const fieldIds = [];
      for (let section of templateTab.sections) {
        for (let field of section.template_fields) {
          if (field.id === templateTab.start_node_field) {
            fieldIds.push(field.id);
          }
          if (field.id === templateTab.end_node_field) {
            fieldIds.push(field.id);
          }
        }
      }

      return fieldIds;
    },
    getLineNodeFieldAndValue(key) {
      const templateTab = this.getTemplateTab();
      if (templateTab[key] === null) {
        return;
      }

      const findFieldById = (fieldId) => {
        for (let section of templateTab.sections) {
          for (let field of section.template_fields) {
            if (field.id === fieldId) {
              return field;
            }
          }
        }
        return null;
      };

      const field = findFieldById(templateTab[key]);
      if (!field) {
        return null;
      }

      const inputValue = this.inputValues.find(
        (inputValue) => inputValue.template_field_id === field.id
      );

      if (!inputValue) {
        return null;
      }

      const nodeIcon =
        field.options && field.options.node_icons
          ? field.options.node_icons[inputValue.value] || null
          : null;

      const iconId = nodeIcon ? nodeIcon.icon_id : null;
      if (iconId === null) {
        return null;
      }

      return {
        size: nodeIcon.size,
        isBackward: false,
        color: nodeIcon.color || '#000000',
        position: key === 'start_node_field' ? 0 : 1,
        iconId,
        fieldId: field.id,
      };
    },
    handleSampleModalBodyScroll() {
      this.isCloseButtonDisabled = true;
      this.handleSampleModalBodyScrollEndDebounced();
    },
    handleSampleModalBodyScrollEnd() {
      this.isCloseButtonDisabled = false;
    },
    toggleAutoSave() {
      if (!this.autoSaveEnabled) {
        this.autoSaveEnabled = true;
      } else {
        useDialogStore().confirmDanger(
          'Disable Auto Save?',
          'We recommend saving regularly to avoid data loss. Your data is safest in the cloud. Tip: You can click "Unsaved Changes" at the top while you continue collecting data.',
          () => {
            this.autoSaveEnabled = false;
          }
        );
      }
    },
    cancelNextSave() {
      if (this.saveTimeout) {
        clearTimeout(this.saveTimeout);
        this.saveTimeout = null;
      }
    },
    scheduleNextSave() {
      if (this.saveTimeout) {
        return;
      }
      this.saveTimeout = setTimeout(
        () => {
          this.saveTimeout = null;
          if (
            this.autoSaveEnabled &&
            navigator.onLine &&
            !this.saving &&
            this.show &&
            !this.isReadOnly &&
            (this.hasChangedInputValues || !this.sampleId) &&
            !this.$refs['sample-form']?.needsToChooseAnApp
          ) {
            this.save(true);
          }
        },
        // Don't immediately save new samples
        this.sampleId ? 20000 : 120000
      );
    },
  },
  created() {
    this.handleSampleModalBodyScrollEndDebounced = _debounce(
      this.handleSampleModalBodyScrollEnd,
      200
    );
  },
  mounted() {
    this.updatePersistence({
      useCamera: false,
      useVideo: false,
      usePreview: false,
      useDrawing: false,
      previewFile: null,
      assetFile: null,
    });

    const lsSaveEnabled = localStorage.getItem('autoSaveEnabled');
    this.autoSaveEnabled = lsSaveEnabled === null || lsSaveEnabled === 'true';
    this.autoSaveInterval = setInterval(() => {
      if (!this.autoSaveEnabled) {
        return;
      }
      if (this.hasUnsavedFiles) {
        console.warn(
          'Unsaved files detected, skipping auto save as scroll height is lost.'
        );
        return;
      }
      this.scheduleNextSave();
    }, 5000);

    this.$root.$on(
      'inputValueUpdated',
      ({ inputValue, isDefaultInputValue, isChanged }) => {
        this.cancelNextSave();
        this.scheduleNextSave();
        if (this.loadingSample || isDefaultInputValue) {
          return;
        }

        if (!this.hasChangedInputValues && isChanged) {
          this.hasChangedInputValues = true;
        }
      }
    );

    this.$nextTick(() => {
      this.showCheck();
    });
  },
  // vue 2
  beforeDestroy() {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
      this.autoSaveInterval = null;
    }
  },
  // vue 3
  beforeUnmount() {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
      this.autoSaveInterval = null;
    }
  },
};
</script>
